window.addEventListener("load", function () {
    window.addEventListener("scroll", function (e) {
        let scrollPosition =
        window.pageYOffset ||
        window.scrollY ||
        document.body.scrollTop ||
        document.documentElement.scrollTop;

        let l_accueil = $('#accueil').height() + 40;
        let l_mission = l_accueil + $('#mission').height() + 90;
        let l_about =  l_mission + $('#a_propos').height() + 90;
        let l_services = l_about + $('#services').height() + 90;
        let l_contact = l_services + $('#acces_et_contact').height() + 90;
        let l_avis = l_contact + $('#avis').height() + 90;
        let l_partenaires = l_avis + $('#partenaires').height() + 90;
        let l_events = '';
        if ($('#events').length > 0) {
            l_events = l_partenaires + $('#events').height();
        }

        if (scrollPosition <= l_mission) {
            $(".single_page_link").removeClass("active-nav-link");
            $('span[data-target="accueil"]').addClass("active-nav-link");
        }
        else if(scrollPosition > l_mission && scrollPosition <= l_about){
            $(".single_page_link").removeClass("active-nav-link");
            $('span[data-target="a_propos"]').addClass("active-nav-link");
        }
        else if(scrollPosition > l_about && scrollPosition <= l_services){
            $(".single_page_link").removeClass("active-nav-link");
            $('span[data-target="services"]').addClass("active-nav-link");
        }
        else if(scrollPosition > l_services && scrollPosition <= l_contact){
            $(".single_page_link").removeClass("active-nav-link");
            $('span[data-target="acces_et_contact"]').addClass("active-nav-link");
        }
        else if(scrollPosition > l_contact && scrollPosition <= l_avis){
            $(".single_page_link").removeClass("active-nav-link");
            $('span[data-target="avis"]').addClass("active-nav-link");
        }
        else if($('#events').length > 0 && scrollPosition > l_partenaires && scrollPosition <= l_events){
            $(".single_page_link").removeClass("active-nav-link");
            $('span[data-target="events"]').addClass("active-nav-link");
        }
    });

    $('.ac_more').on('click', function(e){
        window.scrollTo($("#mission").offset().top,$("#mission").offset().top);
    });
});
